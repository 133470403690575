
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.popup.active {
    z-index: 9;
    display: block;
}

.popup__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.popup__row-block {

}

.popup__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 60px 25px;
}

.popup__content {
    max-width: 576px;
    width: 100%;
    padding: 48px;
    background: #FFFFFF;
    border-radius: 12px;
    position: relative;
}

.popup__content-small {
    padding: 60px 40px 25px 40px;
}

.popup__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 40px;
    height: 40px;

    background: #F5F5F7;
    border-radius: 8px;

    svg {
        margin: 10px;
    }
}

.popup__close:hover {
    cursor: pointer;
}

.popup__close:hover .popup__close-img {
    -webkit-filter: brightness(0.7);
    filter: brightness(0.7);
}

.popup__close-small {

}

.popup__close-img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: .5s all linear;
    -o-transition: .5s all linear;
    transition: .5s all linear;
}

.popup__social {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 30px;
}

.popup__btn {
    height: 60px;
}

.popup__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #020203;
}

.popup__text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #797780;
}

.popup__text + .popup__text {
    margin-top: 5px;
}

.popup__icon {
    text-align: center;
    margin-bottom: 20px;
}

.popup__text-bold {
    font-weight: 600;
}

.popup__text-accent {
    color: #5C82D7;
}

.popup__text-grey {
    color: #95929C;
}

.popup__text-small {
    font-size: 16px;
}

.popup__text-big {
    font-size: 21px;
    line-height: 25px;
}

.popup__text-center {
    text-align: center;
}

.popup__link {
    margin: 0 0 10px 0;
}

.popup__block + .popup__block {
    margin-top: 40px;
}

.popup__block + .popup__block-small {
    margin-top: 20px;
}

.popup__block + .popup__footer {
    margin-top: 60px;
}

.popup__input {
    border-color: #D2D2D2;
    color: #020203;
    position: relative;
}

.popup__input.error {
    border-color: #FF7C7C;
    color: #FF7C7C;
}

.popup__input.succes {
    border-color: #1E1E22;
    color: #1E1E22;
}

.popup__input-item {
    -webkit-appearance: none;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid;
    border-radius: 5px;
    padding: 15px;
    border-color: inherit;
    font-size: 18px;
    color: #020203;
    outline: none;
    -webkit-transition: .5s all;
    -o-transition: .5s all;
    transition: .5s all;
}

.popup__input-item::-webkit-input-placeholder {
    color: #95929C;
}

.popup__input-item::-moz-placeholder {
    color: #95929C;
}

.popup__input-item:-ms-input-placeholder {
    color: #95929C;
}

.popup__input-item::-ms-input-placeholder {
    color: #95929C;
}

.popup__input-item::placeholder {
    color: #95929C;
}

.popup__input-item:focus {
    border-color: #95929C;
}

.popup__input-text {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: inherit;
    left: 0;
    margin-top: 10px;
}

.popup_pay {
    .popup__title {
        text-align: left;
    }
    .popup__text {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.015em;
        color: #020203;
        text-align: left;
    }
    .popup__text-grey {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.01em;
        color: #797780;
    }
    .popup__text-price {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.015em;
        color: #797780;
        margin-top: 35px;
    }
    .popup__text-bold {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.015em;
        color: #191B1D;
    }

    .popup__block-total {
        margin-top: 20px;
    }
}

.popup_feedback_success {
    .popup__title {
        margin-top: 20px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }

    .popup__block + .popup__block {
        margin-top: 80px;
    }

    .popup_feedback_success_content {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.popup_review_lesson {
    .popup__title {
        font-size: 20px;
        text-align: left;
    }
    .stars_block {

    }
    .stars_badge {
        background: none;
        padding: 0;
    }
    .js-stars {
        margin: 0;
    }

    .js-star {
        margin: 0 6px;
    }

    .popup_review_lesson_block_stars {
        margin-top: 73px;
        padding-left: 10px;
    }

    .popup_review_lesson_block_text {
        margin-top: 32px;

        input {
            background: #FFFFFF;
            border: 1px solid #E6E6E8;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            width: 100%;
            height: 40px;
            padding: 8px 16px;
            outline: none;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: #797780;
        }
        input::placeholder {
            color: #797780;
        }
        input:focus::placeholder {
            color: rgba(121, 119, 128, 0.6);
        }
    }

    .popup_review_lesson_block_button {
        margin-top: 32px;
        .js-send_review_lesson {
            margin: 0;
        }
    }
    .popup_review_lesson_block_help {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.008em;
        color: #191B1D;
        margin-top: 22px;
    }
}

@media screen and (max-width: 450px) {
    .popup__wrapper {
        padding: 0;
        align-items: flex-end;
    }

    .popup__content {
        padding: 24px 16px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .popup__close {
        width: 32px;
        height: 32px;
        right: 16px;

        svg {
            margin: 6px;
        }
    }
}
