.course-single__grid {
    display: grid;
    grid-template-columns: auto 402px;
    overflow: hidden;

    background: #F5F5F7;
    border-radius: 12px;
}

.course-single__grid-content {
    margin-left: 48px;
    margin-top: 48px;
    margin-bottom: 48px;
}

.course-single__grid-img {
    text-align: right;
}

.course-single__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.018em;
    color: #202023;
}

.course-single__content-block {
    margin-top: 33px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #797780;

    .course-single__desc {

    }

    b {
        font-weight: 700;
        color: #202023;
    }

    .course-single__author {
        margin-top: 16px;
    }

    .course-single__price {
        margin-top: 12px;
        b {
            font-size: 20px;
            letter-spacing: -0.015em;
        }
    }
}


.course-single__status-text {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 136%;
    color: #898D93;
}

.course-single__status-progress {
    margin-top: 10px;
}

.course-single__status-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.course-single__content {
    margin-top: 30px;
}

.course-single__btn {

}

.course-single__img {
    width: 100%;
}

.course-single__img-item {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 12px;
}

.course-single__description {
    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.018em;
        color: #202023;
        margin-bottom: 30px;
    }

    ul, ol {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.015em;
        color: #000000;
        list-style: auto inside;
    }

    ol > li {
        margin-bottom: 32px;
    }

    ol li > ul {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #797780;
        list-style: disc outside;
        margin-left: 34px;
        margin-top: 20px;
    }

    ol li > ul li {
        margin-bottom: 8px;
    }

    p {
        font-family: 'Nunito Sans',sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #1E1E22;
    }
}

.course-single__description h4 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.2px;
    color: #1E1E22;
    margin-bottom: 30px;
}

.course-single__description p + p {
    margin-top: 30px;
}

.course-single__description p + h4 {
    margin-top: 30px;
}

.course-single__description h4 + p {
    margin-top: 30px;
}

.course-single__description p + ul {
    margin-top: 30px;
}

.course-single__description ul + p {
    margin-top: 30px;
}

.course-single__description h4 + ul {
    margin-top: 30px;
}

.course-single__description ul + h4 {
    margin-top: 30px;
}

.course-singnle__info {
    margin-top: 13px;
}



.course-info {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #191B1D;

    .course-info__item {
        padding-left: 12px;
        padding-right: 12px;
        border-right: 1px solid #DBDADE;
    }

    .course-info__item:first-child {
        padding-left: 0;
    }

    .course-info__item:last-child {
        border-right: none;
    }
}

.course-info__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}



.course-info__title {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 136%;
    color: #898D93;
}

.course-info__text {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.2px;
    color: #1E1E22;
}

.course-single__footer {
    margin-top: 40px;
}


.course-module__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 32px;
}

.course-module__header .icon-mk {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.course-module__header.active .icon-mk {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.course-module__title {
    font-weight: 600;
    font-size: 25px;
    color: #1E1E22;
    letter-spacing: -0.2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.course-module__title span {
    margin-left: 5px;
}

.course-module__status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.course-module__status-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.course-module__status-progress {
    margin: 10px 0 0 0;
    width: 380px;
}

.course-module__status-text {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-size: 14px;
    color: #898D93;
}

.course-module__content {

}

.lesson-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 275px;
    gap: 20px;
}

.lesson-grid__item {
    height: 100%;
}

.lesson-item {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #F5F5F7;
    border-radius: 8px;
}

.lesson-item:hover .lesson-item__desc {

}

.lesson-item_disabled {
    pointer-events: none;

    .lesson-item__title {
        color: rgba(121, 119, 128, 0.32);
    }

    .lesson-item__desc {
        color: rgba(25, 27, 29, 0.32);
    }
}

.lesson-item_complete {
    .lesson-item {
        border: 1px solid #ADD471;
    }
}

.lesson-item_disabled .lesson-item__progress {
    display: none;
}

.lesson-item_disabled .lesson-item__date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.lesson-item__header-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.lesson-item__balls {
    font-size: 16px;
    color: #5C82D7;
}

.lesson-item__wrapper {
    height: 100%;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.lesson-item__title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #797780;
}

.lesson-item__status {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    border-radius: 4px;
    display: inline-block;
    padding: 6px 8px 6px 24px;
}

.lesson-item__status-completed {
    color: #67A410;
    background: #CFE5AE url("../images/lightning-green.svg") no-repeat 7px;
}

.lesson-item__status-failed {
    color: #5C82D7;
}

.lesson-item__status-start {
    color: #0C64F5;
    background: rgba(162, 194, 250, 0.2) url("../images/lightning-blue.svg") no-repeat  7px;
}

.lesson-item__status-close {
    color: #A4A2A9;
    background: #F5F5F7 url("../images/lock-grey.svg") no-repeat  7px;
}

.lesson-item__status-open {
    color: #F1B506;
    background: rgba(248, 204, 77, 0.32) url("../images/bell-yellow.svg") no-repeat  7px;
}

.lesson-item__content {
    margin-top: 15px;
}

.lesson-item__desc {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #191B1D;
}

.lesson-item__footer {
    padding-top: 35px;
    margin-top: auto;
    width: 100%;
}

.lesson-item__progress {
    width: 100%;
}

.lesson-item__date {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.lesson-item__date::after {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    background: url("../images/lock.svg") center center/contain no-repeat;
}

.lesson-item__date-text {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #898D93;
}

.course-single__modules-item {
    margin-bottom: 32px;
}

.course-single__status {
    margin-top: 40px;
    width: 90%;
}

.course-single__status-info {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #A4A2A9;
    text-align: center;

    b {
        font-weight: 700;
        color: #191B1D;
        margin-left: 8px;
    }

    .course-single__status-info-item {
        display: flex;
        align-items: center;

        &::before {
            content: "";
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 4px;
        }
    }

    .course-single__status-info-item:first-child {
        text-align: left;
    }
    .course-single__status-info-item:last-child {
        text-align: right;
    }

    .course-single__status-info-item-study {
        &::before {
            background: url("../images/course-study.svg") no-repeat;
        }
    }

    .course-single__status-info-item-tasks {
        &::before {
            background: url("../images/course-tasks.svg") no-repeat;
        }
    }

    .course-single__status-info-item-clock {
        &::before {
            background: url("../images/course-clock.svg") no-repeat;
        }
    }
}

.course-single__description-grid {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;

    border: 1px solid #F5F5F7;
    border-radius: 12px;
    display: flex;
    padding: 32px;
    margin-bottom: 60px;

    .course-single__description-grid-item {
        width: 100%;
        flex: 1 0 0%;
    }

    .course-single__description-grid-item-title {
        font-weight: 700;
        color: #000000;
        margin-bottom: 8px;
    }

    .course-single__description-grid-item-text {
        font-weight: 400;
        color: #797780;
    }
}

.course-single-pay-wait-wrapper {
    display: flex;
    justify-content: flex-start;

    .course-single-pay-wait-item {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.008em;
        color: #797780;

        a {
            color: #67A410;
        }
    }

    .course-single-pay-wait-item:first-child {
        margin-right: 6px;
    }
}

@media screen and (max-width: 1100px) {
    .course-single__grid {
        grid-template-columns: auto 300px;
    }
    .course-single__img {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        height: 370px;
    }
    .course-module__header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .course-module__status {
        margin-top: 30px;
    }
    .course-review__input {
        margin-left: 15px;
    }
    .lesson-grid {
        grid-template-columns: repeat(3, minmax(0, 270px));
    }
}

@media screen and (max-width: 991px) {
    .course-single__grid {
        grid-template-columns: auto;
    }
    .course-single__grid-img {
        order: 1;
        text-align: center;
        height: 370px;

        .course-single__img-item {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .course-single__grid-content {
        margin: 16px;
        order: 2;
    }
    .course-single__status {
        width: 100%;
    }
    .course-single__title {
        font-size: 20px;
    }
    .course-review__wrapper {
        height: auto;
        padding: 15px 0;
    }
    .course-review__content {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .course-review__mark, .course-review__input, .course-review__submit {
        margin: 5px 10px;
    }
    .course-review__input-item {
        width: 100%;
    }

    .course-single__author, .course-single__price {
        font-size: 14px;
    }
    .lesson-grid {
      grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 767px) {
    .course-module__title {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .course-module__title span {
        width: 100%;
        padding-left: 56px;
    }
}

@media screen and (max-width: 640px) {
    .course-single__description-grid {
        flex-direction: column;
        padding: 0;
        margin: 0;
        border: none;

        .course-single__description-grid-item {
            margin-bottom: 16px;
        }

    }

    .page-content__header + .page-content__wrapper {
        padding-top: 20px;
    }

    .course-single__status-info .course-single__status-info-item {
        flex-direction: column;
        align-items: normal;

        span {
            display: none;
        }
        b {
            margin: 0;
        }
    }

    .lesson-grid {
      grid-template-columns: 1fr;
    }
}

