.header {
    z-index: 10;
    position: relative;
    background: #fff;
}

.header__wrapper {
    height: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
}

.header__block {
    width: 100%;
}

.header__block-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

    .header__btn {
        display: flex;
        height: 40px;
        padding: 13px 32px;
    }
}

.header__block-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 100%;
}

.header__logo-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #3A4972;
}

.header__profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header__profile-block + .header__profile-block {
    margin-left: 50px;
}

.header__profile-block-delimiter {
    width: 0;
    height: 16px;
    border: 1px solid #E6E6E8;
    margin-left: 16px;
    margin-right: 16px;
}

.header__notification {
    position: relative;
}

.header__btn {
    display: flex;
    max-width: 150px;
    height: 40px;
}

.header-profile {
    position: relative;
}

.header-profile__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-profile__link:hover {
    cursor: pointer;
}

.header-profile__link.active .header-profile__link-icon {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.header-profile__link-icon {
    display: block;
    margin-left: 12px;
    -webkit-transition: .5s all;
    -o-transition: .5s all;
    transition: .5s all;
}

.header-profile__link-icon .icon {
    width: 12px;
    height: 8px;
    color: #1E1E22;
}

.header-profile__name {
    margin-left: 8px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #797780;
    max-width: 115px;
    white-space: nowrap;
    overflow: hidden;
}

.header-profile__img {
    width: 32px;
    height: 32px;
    border-radius: 10px;
    overflow: hidden;
    background: #F5F5F7;
    text-align: center;
    padding: 6px;
}

.header-profile__img-item {
    -o-object-fit: cover;
    object-fit: cover;
}

.header-profile__img-item-empty {
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: 6px;
}

.header-profile__list {
    position: absolute;
    right: -15px;
    top: 50px;
    width: 172px;
    padding: 8px 12px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 12px rgb(40 45 60 / 12%);
    border-radius: 12px;
    display: none;

    .page-nav__item-link {
        height: 36px;
        padding: 0;
        border-radius: 4px;
        .page-nav__item-icon {
            margin-left: 12px;
            padding-top: 4px;
        }
        .page-nav__item-text {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #191B1D;
            margin-left: 8px;
        }
    }
    .page-nav__item-link:hover {
        .page-nav__item-text {
            color: #47494A;
        }
    }
}

.header-profile__list.active {
    display: block;
    z-index: 99;
    -webkit-animation: showHide 0.5s forwards;
    animation: showHide 0.5s forwards;
}

.header-nav__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-list__item + .header-list__item {
    margin-left: 60px;
}

.header-list__link {
    color: #191B1D;
    -webkit-transition: .3s color;
    -o-transition: .3s color;
    transition: .3s color;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
}

.header-list__link span {
    display: none;
}

.header-list__link:hover {
    color: #47494ACC;
}

.header-list__link-active {
    color: #67A410;
}

.notification-link {
    width: 32px;
    height: 32px;
    position: relative;
    background: #F5F5F7;
    border-radius: 10px;
    padding: 6px;
    cursor: pointer;
}

.notification-link:hover {
    svg {
        path {
            stroke: #ADD471;
        }
    }
}

.notification-link__count {
    position: absolute;
    right: -6px;
    top: -2px;
    width: 17px;
    height: 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ADD471;
    font-size: 10px;
    color: #fff;
    letter-spacing: -0.306546px;
    -webkit-transition: .5s background-color;
    -o-transition: .5s background-color;
    transition: .5s background-color;
}

.notification-block {
    position: absolute;
    right: -160px;
    top: 50px;
    width: 320px;
    padding: 16px 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(40, 45, 60, 0.12);
    border-radius: 12px;
    display: none;
}

.notification-block.active {
    display: block;
    z-index: 99;
    -webkit-animation: showHide 0.5s forwards;
    animation: showHide 0.5s forwards;
}

.notification-block__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
        font-family: 'Montserrat';
        color: #191B1D;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
    a {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #67A410;
        padding-bottom: 4px;
    }
}

.notification-block__content {
    margin-top: 16px;
    max-height: 300px;
    overflow: hidden auto;
}

.notification-block__footer {
    margin-top: 30px;
}

.notification-block__btn {
    height: 60px;
}

.notification-item + .notification-item {
    margin-top: 12px;
}

.notification-item_read .notification-item__wrapper::before {
    opacity: 0;
}

.notification-item__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}



.notification-item__time {
    font-family: 'Nunito Sans', sans-serif;
    color: #A4A2A9;
    margin-left: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.notification-item__dot span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #ADD471;
    display: block;
    margin-top: 7px;
}

.notification-item__content {
    margin-left: 8px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E22;
}

.notification-item__content a {
    color: #797780;
    display: block;
}

.notification-item__text {
    font-family: 'Nunito Sans', sans-serif;
    color: #1E1E22;
    font-size: 16px;
    line-height: 24px;
}

.header__menu-block-mobile {
    display: none;
}

.header__logo.mobile {
    display: none;
}

.header__logo-mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    .header-list__item + .header-list__item {
        margin-left: 32px;
    }
}

@media screen and (max-width: 991px) {
    .header__block {
        max-width: unset;
        width: unset;
    }
    .header__profile-block + .header__profile-block {
        margin: 0;
    }
    .header__nav {
        //display: none;
    }
    .header__nav-mobile {
        //display: block;
    }
    .header-list__item {
        display: none;
    }
    .header-list__item + .header-list__item {
        margin: 0;
    }
    .header-list__item-mobile + .header-list__item-mobile {
        margin: 0 0 0 50px;
    }
    .header-list__item-mobile {
        display: block;
    }
    .page-nav__item {
        display: block;
    }
    .page-nav__item_mobile {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .header-list__item + .header-list__item {
        margin-left: 20px;
    }

    .header__logo.desktop {
        display: none;
    }

    .header__logo.mobile {
        display: block;
    }

    .header__profile-block-delimiter {
        margin-left: 0;
        margin-right: 12px;
        border: none;
    }

    .header-list__link {
        font-size: 14px;
    }

    .notification-link {
        width: 28px;
        height: 28px;
        border-radius: 6px;

        svg {
            width: 16px;
            height: 16px;
            display: block;
        }
    }

    .header-profile__img {
        width: 28px;
        height: 28px;
        border-radius: 6px;
        svg {
            width: 16px;
            height: 16px;
            display: block;
        }
    }
}

@media screen and (max-width: 600px) {
    .header__btn {
        max-width: 100px;
    }
    .header-list__link span {
        display: inline-block;
    }
    .notification-block {
        width: 100vw;
        top: 40px;
        right: -131px;
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .notification-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        .notification-item__text {
            font-size: 14px;
        }
    }

    .page-nav__item_mobile {
        display: block;
    }
    .header-profile__name {
        display: none;
    }

    .header-menu__link-icon {
        width: 32px;
        height: 32px;
        position: relative;
        background: #F5F5F7;
        border-radius: 10px;
        padding: 6px;
    }

    .header__menu-block-mobile {
        .header-profile__list {
            width: 100vw;
            top: 40px;
            box-shadow: none;
            padding: 16px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            right: -26px;
        }

        .page-nav__item {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-size: 16px;
            font-weight: 600;
            padding: 8px;
            line-height: 16px;
        }
    }

    .header__profile-block-mobile {
        .header-profile__list {
            width: 100vw;
            top: 40px;
            box-shadow: none;
            padding: 16px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            right: -25px;
        }

        .page-nav__item-link:hover {
            background-color: #FFFFFF;
        }
    }
}

@media screen and (max-width: 450px) {
    .header__nav {
        display: none;
    }

    .header__menu-block-mobile {
        display: block;
    }
    .header-profile__link-icon {
        display: none;
    }

    .notification-block {
        right: -100px;
    }

    .header__menu-block-mobile {
        .header-profile__list {
            right: -16px;
        }
    }

    .header__profile-block-mobile {
        .header-profile__list {
            right: -60px;
        }
    }
}
