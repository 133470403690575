@import "variables";

.btn {
    -webkit-appearance: none;
    border: none;
    outline: none;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: 'Nunito Sans', sans-serif;
    padding: 16px 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.01em;
    border-radius: 8px;
}

.btn-fill {
    width: 100%;
    text-align: center;
}

.btn-accent {
    background-color: $buttonColor;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    border-radius: 8px;
    -webkit-transition: .5s background-color;
    -o-transition: .5s background-color;
    transition: .5s background-color;
}

.btn-accent:hover {
    background: #47494A;
}

.btn-accent-light {
    font-size: 16px;
    font-weight: 600;
    color: #67A410;
    border-radius: 4px;
    -webkit-transition: .5s background-color;
    -o-transition: .5s background-color;
    transition: .5s background-color;
}

.btn-back::before {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    //background: url("../img/arrow-back.svg") center center/contain no-repeat;
    margin: 0 8px 0 0;
}

.btn-course-go {
    background: #ADD471;
    color: #191B1D;
}

.btn-course-go:hover {
    background: #C4E099;
}

.btn-course-go-light {
    background: #C4E099;
    color: #191B1D;
}

.btn-gray {
    background: #F5F5F7;
}

@media screen and (max-width: 640px) {
    .btn {
        padding: 16px 32px;
    }
}

@media screen and (max-width: 450px) {
    .btn-fill-mobile {
        width: 100%;
        text-align: center;
    }
}
