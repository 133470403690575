
#html_review_lesson .page-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}
.js-block_review_lesson {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-between;
    font-size: 16px;
    color: #95929C;
    height: 70px;
}
.js-stars {
    margin-left: 15px;
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 0;
}
.js-star {
    display: flex;
    margin: 0 5px;
}
.js-star:hover svg path,
.js-star:hover ~.js-star svg path,
.js-star.my_rating svg path,
.js-star.my_rating ~.js-star svg path {
    fill: #ADD471;
    stroke: #ADD471;
}
.js-star svg path {
    -webkit-transition: .5s all;
    -o-transition: .5s all;
    transition: .5s all;
}
#html_review_lesson {
    overflow-y: auto;
}
.js-block_review_lesson input {
    margin-left: 45px;
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 370px;
    height: 40px;
    font-size: 16px;
    padding: 8px 16px;
    outline: none;
}
.js-block_review_lesson input::placeholder {
    color: #95929C;
}
.js-send_review_lesson {
    margin-left: 20px;
    line-height: 8px;
}

.stars_block {
    display: flex;
}

.stars_badge {
    background: #F5F5F7;
    border-radius: 8px;
    padding: 8px 32px;
    display: flex;
}

.stars_caption {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #191B1D;
}

.js-button_review_lesson {
    display: none;
}

@media screen and (max-width: 1040px) {
    .js-block_review_lesson input {
        margin-left: 25px;
    }

    .stars_badge {
        padding: 8px 12px;
    }
}

@media screen and (max-width: 991px) {
    #html_review_lesson {
        padding: 10px 0;
    }
    .js-block_review_lesson input {
        font-size: 14px;
        height: 35px;
        width: 100%;
        margin-left: 0px;
    }
    .js-block_review_lesson {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .js-block_review_lesson > div {
        margin: 0 5px;
    }

    .js-star {
        margin: 0 2px;
    }
}

@media screen and (max-width: 880px) {
    .js-block_review_lesson {
        display: none;
    }
    .js-button_review_lesson {
        display: block;
    }
}
