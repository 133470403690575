.footer {
    position: relative;
    z-index: 8;
    background: #191B1D;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    color: #A4A2A9;
}

.footer__wrapper {
    padding: 16px 0 16px 0;
}

.footer__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 20px;
}

.footer__row + .footer__row {
    margin-top: 32px;
}

.footer__logo {
    height: 60px;
    max-width: 228px;
    width: 100%;
    svg {
        fill: #FFFFFF;
        height: 20px;
    }
}

.footer__logo.mobile {
    display: none;
}

.footer__contact {
    color: #FFFFFF;
    background-image: url("../images/link.png");
    background-repeat: no-repeat;
    background-position: right;
    padding-right: 18px;
    height: 20px;
    line-height: 20px;
}

.footer__link {
    text-align: right;
    height: 20px;
    line-height: 20px;
}

.footer__link:hover {
    color: #FFFFFF;
}

.footer__block {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 450px) {
    .footer__link {
        max-width: 88px;
        display: block;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .footer__logo.desktop {
        display: none;
    }
    .footer__logo.mobile {
        display: initial;
    }
}

