.certificate-card__wrapper {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #E6E6E8;
    margin-bottom: 32px;
    height: 80px;
}

.certificate-card__text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #797780;
    display: flex;
    margin-top: 13px;

    .certificate-card__text-item {
        padding: 0 12px;
        border-right: 1px solid #DBDADE;
    }
    .certificate-card__text-item:first-child {
        padding-left: 0;
    }
    .certificate-card__text-item:last-child {
        border-right: none;
    }
}

.certificate-card__name {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #191B1D;
}

.certificate-card__content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80%;
}

.certificate-card__footer {
    width: 20%;
    text-align: right;
    padding-top: 6px
}

.certificate-card__btn {
    max-width: 200px;
    height: 50px;
}

.certificate-info__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #5C82D7;
}

.certificate-info__date {
    margin: 15px 0 0 0;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 136%;
    color: #898D93;
}

.certificate-info__footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 270px));
    gap: 20px;
    margin: 40px 0 0 0;
}

.certificate-info__btn {
    height: 50px;
}

.certificate-single__wrapper {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 12px rgba(92, 130, 215, 0.08);
    box-shadow: 0px 0px 12px rgba(92, 130, 215, 0.08);
    border-radius: 15px;
    max-width: 650px;
    width: 100%;
    padding: 12px 0 25px 36px;
    position: relative;
    overflow: hidden;
}

.certificate-single__wrapper::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 120px;
    height: 120px;
    display: block;
}

.certificate-single__text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1E1E22;
}

.certificate-single__name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1E1E22;
    position: relative;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
}

.certificate-single__name::before {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -4px;
    width: calc(100% - 40px);
    height: 4px;
    background: #F8D47A;
}

.certificate-single__content-header {
    margin: 18px 0 0 0;
}

.certificate-single__content-name {
    margin: 27px 0 0 0;
}

.certificate-single__content-footer {
    margin: 40px 0 0 0;
}

.certificate-single__footer {
    margin: 50px 0 0 0;
    padding: 0 30px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.certificate-single__share {
    max-width: 650px;
    position: relative;
}

.certificate-logo__text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #1E1E22;
}

.certificate-share {
    position: absolute;
    left: calc(100% + 5px);
    top: -36px;
}

.certificate-share__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 136%;
    color: #898D93;
}

.certificate-share__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 0 15px;
}

.certificate-share__link {
    width: 36px;
    height: 36px;
    display: block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.certificate-share__link_vk {

}

.certificate-share__link_tg {

}

.certificate-share__link + .certificate-share__link {
    margin: 0 0 0 15px;
}

.certificate__single {
    margin: 40px 0 0 0;
}

.profile-content__block {
    margin-top: 60px;
}

@media screen and (max-width: 991px) {
    .certificate-info__footer {
        margin: 30px 0 0 0;
    }
    .certificate__single {
        margin: 30px 0 0 0;
    }
    .certificate-single__share {
        margin: 30px 0 0 0;
    }
    .certificate-share {
        position: relative;
        left: 0;
        top: 0;
    }
}

@media screen and (max-width: 600px) {
    .certificate-card__content {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .certificate-card__name {

    }
    .certificate-single__wrapper {
        padding: 7px 0 15px 20px;
    }
    .certificate-single__text {
        font-size: 8px;
        line-height: 10px;
    }
    .certificate-single__name {
        font-size: 11px;
        line-height: 14px;
    }
    .certificate-single__name::before {
        left: 12px;
        width: calc(100% - 24px);
    }
    .certificate-single__footer {
        margin: 25px 0 0 0;
        padding: 0 18px 0 0;
    }
    .certificate-single__content-header {
        margin: 10px 0 0 0;
    }
    .certificate-single__content-name {
        margin: 14px 0 0 0;
    }
    .certificate-single__content-footer {
        margin: 22px 0 0 0;
    }
    .certificate-logo__text {
        font-size: 5px;
        line-height: 6px;
    }
}
