.payment-succes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.payment-succes__wrapper {
    max-width: 480px;
    width: 100%;
}

.payment-succes__title {
    text-align: center;
}

.payment-succes__img {
    width: 115px;
    margin: 35px auto 20px auto;
    text-align: center;
}

.payment-succes__img-item {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.payment-succes__text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #797780;
    margin-top: 16px;

    a {
        color: #67A410;
    }
}

.payment-succes__btn {
    margin-top: 35px;
}
