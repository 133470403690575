.header {
  z-index: 10;
  position: relative;
  background: #fff;
}

.header__wrapper {
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
}

.header__block {
  width: 100%;
}

.header__block-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.header__block-btn .header__btn {
  display: flex;
  height: 40px;
  padding: 13px 32px;
}

.header__block-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 100%;
}

.header__logo-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #3A4972;
}

.header__profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header__profile-block + .header__profile-block {
  margin-left: 50px;
}

.header__profile-block-delimiter {
  width: 0;
  height: 16px;
  border: 1px solid #E6E6E8;
  margin-left: 16px;
  margin-right: 16px;
}

.header__notification {
  position: relative;
}

.header__btn {
  display: flex;
  max-width: 150px;
  height: 40px;
}

.header-profile {
  position: relative;
}

.header-profile__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-profile__link:hover {
  cursor: pointer;
}

.header-profile__link.active .header-profile__link-icon {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.header-profile__link-icon {
  display: block;
  margin-left: 12px;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.header-profile__link-icon .icon {
  width: 12px;
  height: 8px;
  color: #1E1E22;
}

.header-profile__name {
  margin-left: 8px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #797780;
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
}

.header-profile__img {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  overflow: hidden;
  background: #F5F5F7;
  text-align: center;
  padding: 6px;
}

.header-profile__img-item {
  -o-object-fit: cover;
  object-fit: cover;
}

.header-profile__img-item-empty {
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 6px;
}

.header-profile__list {
  position: absolute;
  right: -15px;
  top: 50px;
  width: 172px;
  padding: 8px 12px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 12px rgba(40, 45, 60, 0.12);
  border-radius: 12px;
  display: none;
}
.header-profile__list .page-nav__item-link {
  height: 36px;
  padding: 0;
  border-radius: 4px;
}
.header-profile__list .page-nav__item-link .page-nav__item-icon {
  margin-left: 12px;
  padding-top: 4px;
}
.header-profile__list .page-nav__item-link .page-nav__item-text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #191B1D;
  margin-left: 8px;
}
.header-profile__list .page-nav__item-link:hover .page-nav__item-text {
  color: #47494A;
}

.header-profile__list.active {
  display: block;
  z-index: 99;
  -webkit-animation: showHide 0.5s forwards;
  animation: showHide 0.5s forwards;
}

.header-nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-list__item + .header-list__item {
  margin-left: 60px;
}

.header-list__link {
  color: #191B1D;
  -webkit-transition: 0.3s color;
  -o-transition: 0.3s color;
  transition: 0.3s color;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
}

.header-list__link span {
  display: none;
}

.header-list__link:hover {
  color: rgba(71, 73, 74, 0.8);
}

.header-list__link-active {
  color: #67A410;
}

.notification-link {
  width: 32px;
  height: 32px;
  position: relative;
  background: #F5F5F7;
  border-radius: 10px;
  padding: 6px;
  cursor: pointer;
}

.notification-link:hover svg path {
  stroke: #ADD471;
}

.notification-link__count {
  position: absolute;
  right: -6px;
  top: -2px;
  width: 17px;
  height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ADD471;
  font-size: 10px;
  color: #fff;
  letter-spacing: -0.306546px;
  -webkit-transition: 0.5s background-color;
  -o-transition: 0.5s background-color;
  transition: 0.5s background-color;
}

.notification-block {
  position: absolute;
  right: -160px;
  top: 50px;
  width: 320px;
  padding: 16px 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(40, 45, 60, 0.12);
  border-radius: 12px;
  display: none;
}

.notification-block.active {
  display: block;
  z-index: 99;
  -webkit-animation: showHide 0.5s forwards;
  animation: showHide 0.5s forwards;
}

.notification-block__title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.notification-block__title p {
  font-family: "Montserrat";
  color: #191B1D;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.notification-block__title a {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #67A410;
  padding-bottom: 4px;
}

.notification-block__content {
  margin-top: 16px;
  max-height: 300px;
  overflow: hidden auto;
}

.notification-block__footer {
  margin-top: 30px;
}

.notification-block__btn {
  height: 60px;
}

.notification-item + .notification-item {
  margin-top: 12px;
}

.notification-item_read .notification-item__wrapper::before {
  opacity: 0;
}

.notification-item__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.notification-item__time {
  font-family: "Nunito Sans", sans-serif;
  color: #A4A2A9;
  margin-left: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.notification-item__dot span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ADD471;
  display: block;
  margin-top: 7px;
}

.notification-item__content {
  margin-left: 8px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1E1E22;
}

.notification-item__content a {
  color: #797780;
  display: block;
}

.notification-item__text {
  font-family: "Nunito Sans", sans-serif;
  color: #1E1E22;
  font-size: 16px;
  line-height: 24px;
}

.header__menu-block-mobile {
  display: none;
}

.header__logo.mobile {
  display: none;
}

.header__logo-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header-list__item + .header-list__item {
    margin-left: 32px;
  }
}
@media screen and (max-width: 991px) {
  .header__block {
    max-width: unset;
    width: unset;
  }
  .header__profile-block + .header__profile-block {
    margin: 0;
  }
  .header-list__item {
    display: none;
  }
  .header-list__item + .header-list__item {
    margin: 0;
  }
  .header-list__item-mobile + .header-list__item-mobile {
    margin: 0 0 0 50px;
  }
  .header-list__item-mobile {
    display: block;
  }
  .page-nav__item {
    display: block;
  }
  .page-nav__item_mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header-list__item + .header-list__item {
    margin-left: 20px;
  }
  .header__logo.desktop {
    display: none;
  }
  .header__logo.mobile {
    display: block;
  }
  .header__profile-block-delimiter {
    margin-left: 0;
    margin-right: 12px;
    border: none;
  }
  .header-list__link {
    font-size: 14px;
  }
  .notification-link {
    width: 28px;
    height: 28px;
    border-radius: 6px;
  }
  .notification-link svg {
    width: 16px;
    height: 16px;
    display: block;
  }
  .header-profile__img {
    width: 28px;
    height: 28px;
    border-radius: 6px;
  }
  .header-profile__img svg {
    width: 16px;
    height: 16px;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .header__btn {
    max-width: 100px;
  }
  .header-list__link span {
    display: inline-block;
  }
  .notification-block {
    width: 100vw;
    top: 40px;
    right: -131px;
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .notification-block .notification-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .notification-block .notification-item__text {
    font-size: 14px;
  }
  .page-nav__item_mobile {
    display: block;
  }
  .header-profile__name {
    display: none;
  }
  .header-menu__link-icon {
    width: 32px;
    height: 32px;
    position: relative;
    background: #F5F5F7;
    border-radius: 10px;
    padding: 6px;
  }
  .header__menu-block-mobile .header-profile__list {
    width: 100vw;
    top: 40px;
    box-shadow: none;
    padding: 16px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    right: -26px;
  }
  .header__menu-block-mobile .page-nav__item {
    font-family: "Nunito Sans";
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    line-height: 16px;
  }
  .header__profile-block-mobile .header-profile__list {
    width: 100vw;
    top: 40px;
    box-shadow: none;
    padding: 16px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    right: -25px;
  }
  .header__profile-block-mobile .page-nav__item-link:hover {
    background-color: #FFFFFF;
  }
}
@media screen and (max-width: 450px) {
  .header__nav {
    display: none;
  }
  .header__menu-block-mobile {
    display: block;
  }
  .header-profile__link-icon {
    display: none;
  }
  .notification-block {
    right: -100px;
  }
  .header__menu-block-mobile .header-profile__list {
    right: -16px;
  }
  .header__profile-block-mobile .header-profile__list {
    right: -60px;
  }
}
.footer {
  position: relative;
  z-index: 8;
  background: #191B1D;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.005em;
  color: #A4A2A9;
}

.footer__wrapper {
  padding: 16px 0 16px 0;
}

.footer__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 20px;
}

.footer__row + .footer__row {
  margin-top: 32px;
}

.footer__logo {
  height: 60px;
  max-width: 228px;
  width: 100%;
}
.footer__logo svg {
  fill: #FFFFFF;
  height: 20px;
}

.footer__logo.mobile {
  display: none;
}

.footer__contact {
  color: #FFFFFF;
  background-image: url("../images/link.png");
  background-repeat: no-repeat;
  background-position: right;
  padding-right: 18px;
  height: 20px;
  line-height: 20px;
}

.footer__link {
  text-align: right;
  height: 20px;
  line-height: 20px;
}

.footer__link:hover {
  color: #FFFFFF;
}

@media screen and (max-width: 450px) {
  .footer__link {
    max-width: 88px;
    display: block;
    white-space: nowrap;
    overflow-x: hidden;
  }
  .footer__logo.desktop {
    display: none;
  }
  .footer__logo.mobile {
    display: initial;
  }
}
.container {
  max-width: 1220px;
  padding: 0 25px;
  width: 100%;
  margin: 0 auto;
}

.page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}

.page-wrapper__header, .page-wrapper__footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
}

.page-wrapper__content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 40px 0;
}

.page-wrapper__content_big {
  padding: 40px 0;
}

.page-wrapper__content_banner {
  padding: 160px 0 115px 0;
}

.page-content__header {
  position: relative;
}

.page-content__header-block + .page-content__header-block {
  margin-top: 40px;
}

.page-content__header-back {
  position: absolute;
  left: 0;
  top: -40px;
}

.page-content__header-back + .page-content__header-block {
  padding-top: 40px;
}

.page-content__title {
  color: #191B1D;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.page-content__subtitle {
  margin: 15px 0 0 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #5C82D7;
}

.page-content__desc {
  margin-top: 40px;
}

.page-content__header + .page-content__wrapper {
  padding-top: 30px;
}

.tabs-nav {
  z-index: 1;
  position: relative;
  border-bottom: 2px solid #F5F5F7;
}

.page-tabs__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-tabs__nav-item {
  height: 36px;
  color: rgba(121, 119, 128, 0.6784313725);
  border-bottom: 2px solid transparent;
  -webkit-transition: 0.5s border-bottom-color;
  -o-transition: 0.5s border-bottom-color;
  transition: 0.5s border-bottom-color;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 16px;
  position: relative;
  bottom: -2px;
}

.page-tabs__nav-item.active {
  color: #191B1D;
  border-bottom-color: #191B1D;
}

.page-tabs__nav-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: inherit;
  -webkit-transition: 0.5s color;
  -o-transition: 0.5s color;
  transition: 0.5s color;
  outline: none;
}

.page-tabs__content {
  margin-top: 32px;
}

.page-tabs__content-small {
  padding-top: 40px;
}

.page-nav {
  width: 100%;
}

.page-nav__item {
  display: none;
}

.page-nav__item_desktop {
  display: block;
}

.page-nav__item-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 55px;
  border-radius: 10px;
  padding: 0 18px;
  -webkit-transition: 0.5s background-color;
  -o-transition: 0.5s background-color;
  transition: 0.5s background-color;
}

.page-nav__item-link:hover {
  background-color: rgba(235, 235, 235, 0.5);
}

.page-nav__item-link_not-read {
  position: relative;
}

.page-nav__item-link_not-read::after {
  content: "";
  width: 7px;
  height: 7px;
  display: block;
  border-radius: 50%;
  margin-left: auto;
  background-color: #5C82D7;
}

.page-nav__item-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.page-nav__item-icon svg {
  width: 100%;
  height: 100%;
}

.page-nav__item-text {
  margin-left: 15px;
  font-weight: 600;
  font-size: 17px;
  color: #232B3F;
}

.tabs-nav-mobile {
  display: none;
}

.breadcrumb {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.005em;
  display: flex;
}
.breadcrumb .breadcrumb-item {
  color: #67A410;
  padding-right: 8px;
  padding-left: 8px;
  border-right: 1px solid rgba(121, 119, 128, 0.2);
}
.breadcrumb .breadcrumb-item a {
  color: #A4A2A9;
}
.breadcrumb .breadcrumb-item:first-child {
  padding-left: 0;
}
.breadcrumb .breadcrumb-item:last-child {
  border-right: none;
}
.breadcrumb .breadcrumb-item-icon a {
  display: flex;
  justify-content: flex-start;
  line-height: 22px;
}
.breadcrumb .breadcrumb-item-icon a svg {
  margin-right: 6px;
}

.breadcrumb-mobile {
  display: none;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background: #E6E6E8;
  border-radius: 12px;
  position: relative;
}

.progress-bar_big {
  height: 10px;
  border-radius: 0;
}

.progress-bar-current {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  background: #ADD471;
  border-radius: 12px;
}

.click-url {
  cursor: pointer;
}

#body_overlay {
  background: rgba(0, 0, 0, 0.2);
}

#body_overlay.active {
  display: none;
}

@media screen and (max-width: 480px) {
  .page-tabs__nav-link span {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .container {
    padding: 0 16px;
  }
  .breadcrumb-mobile {
    display: flex;
  }
  .breadcrumb-desktop {
    display: none;
  }
  .page-tabs__nav-item {
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 0;
  }
  #body_overlay.active {
    display: block;
  }
}
.btn {
  -webkit-appearance: none;
  border: none;
  outline: none;
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Nunito Sans", sans-serif;
  padding: 16px 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  border-radius: 8px;
}

.btn-fill {
  width: 100%;
  text-align: center;
}

.btn-accent {
  background-color: #191B1D;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  border-radius: 8px;
  -webkit-transition: 0.5s background-color;
  -o-transition: 0.5s background-color;
  transition: 0.5s background-color;
}

.btn-accent:hover {
  background: #47494A;
}

.btn-accent-light {
  font-size: 16px;
  font-weight: 600;
  color: #67A410;
  border-radius: 4px;
  -webkit-transition: 0.5s background-color;
  -o-transition: 0.5s background-color;
  transition: 0.5s background-color;
}

.btn-back::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 8px 0 0;
}

.btn-course-go {
  background: #ADD471;
  color: #191B1D;
}

.btn-course-go:hover {
  background: #C4E099;
}

.btn-course-go-light {
  background: #C4E099;
  color: #191B1D;
}

.btn-gray {
  background: #F5F5F7;
}

@media screen and (max-width: 640px) {
  .btn {
    padding: 16px 32px;
  }
}
@media screen and (max-width: 450px) {
  .btn-fill-mobile {
    width: 100%;
    text-align: center;
  }
}
.icon-mk {
  display: inline-block;
  min-width: 28px;
  min-height: 28px;
  background-image: url("../images/icons.svg");
}

.icon-mk-medium {
  width: 20px;
  height: 20px;
}

.icon-mk-bell-big {
  background-position: -17px -15px;
}

.icon-mk-bell-medium {
  background-position: -58px -20px;
}

.icon-mk-profile-big {
  background-position: -21px -50px;
}

.icon-mk-profile-medium {
  background-position: -58px -50px;
}

.icon-mk-logout-big {
  background-position: -19px -360px;
}

.icon-mk-logout-medium {
  background-position: -58px -360px;
}

.icon-mk-arrow-down-big {
  background-position: -19px -72px;
}

.icon-mk-arrow-up-big {
  background-position: -10px -10px;
}

.icon-mk-student-big {
  background-position: -10px -10px;
}

.icon-mk-tasks-big {
  background-position: -10px -10px;
}

.icon-mk-clock-big {
  background-position: -10px -10px;
}

.icon-mk-menu-medium {
  background-position: -60px -132px;
}

.active .icon-mk-menu-medium {
  background-position: -58px -164px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.popup.active {
  z-index: 9;
  display: block;
}

.popup__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.popup__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 60px 25px;
}

.popup__content {
  max-width: 576px;
  width: 100%;
  padding: 48px;
  background: #FFFFFF;
  border-radius: 12px;
  position: relative;
}

.popup__content-small {
  padding: 60px 40px 25px 40px;
}

.popup__close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  background: #F5F5F7;
  border-radius: 8px;
}
.popup__close svg {
  margin: 10px;
}

.popup__close:hover {
  cursor: pointer;
}

.popup__close:hover .popup__close-img {
  -webkit-filter: brightness(0.7);
  filter: brightness(0.7);
}

.popup__close-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transition: 0.5s all linear;
  -o-transition: 0.5s all linear;
  transition: 0.5s all linear;
}

.popup__social {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 30px;
}

.popup__btn {
  height: 60px;
}

.popup__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #020203;
}

.popup__text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #797780;
}

.popup__text + .popup__text {
  margin-top: 5px;
}

.popup__icon {
  text-align: center;
  margin-bottom: 20px;
}

.popup__text-bold {
  font-weight: 600;
}

.popup__text-accent {
  color: #5C82D7;
}

.popup__text-grey {
  color: #95929C;
}

.popup__text-small {
  font-size: 16px;
}

.popup__text-big {
  font-size: 21px;
  line-height: 25px;
}

.popup__text-center {
  text-align: center;
}

.popup__link {
  margin: 0 0 10px 0;
}

.popup__block + .popup__block {
  margin-top: 40px;
}

.popup__block + .popup__block-small {
  margin-top: 20px;
}

.popup__block + .popup__footer {
  margin-top: 60px;
}

.popup__input {
  border-color: #D2D2D2;
  color: #020203;
  position: relative;
}

.popup__input.error {
  border-color: #FF7C7C;
  color: #FF7C7C;
}

.popup__input.succes {
  border-color: #1E1E22;
  color: #1E1E22;
}

.popup__input-item {
  -webkit-appearance: none;
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid;
  border-radius: 5px;
  padding: 15px;
  border-color: inherit;
  font-size: 18px;
  color: #020203;
  outline: none;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.popup__input-item::-webkit-input-placeholder {
  color: #95929C;
}

.popup__input-item::-moz-placeholder {
  color: #95929C;
}

.popup__input-item:-ms-input-placeholder {
  color: #95929C;
}

.popup__input-item::-ms-input-placeholder {
  color: #95929C;
}

.popup__input-item::placeholder {
  color: #95929C;
}

.popup__input-item:focus {
  border-color: #95929C;
}

.popup__input-text {
  font-family: "Nunito Sans", sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: inherit;
  left: 0;
  margin-top: 10px;
}

.popup_pay .popup__title {
  text-align: left;
}
.popup_pay .popup__text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #020203;
  text-align: left;
}
.popup_pay .popup__text-grey {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #797780;
}
.popup_pay .popup__text-price {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #797780;
  margin-top: 35px;
}
.popup_pay .popup__text-bold {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #191B1D;
}
.popup_pay .popup__block-total {
  margin-top: 20px;
}

.popup_feedback_success .popup__title {
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.popup_feedback_success .popup__block + .popup__block {
  margin-top: 80px;
}
.popup_feedback_success .popup_feedback_success_content {
  margin-top: 50px;
  margin-bottom: 50px;
}

.popup_review_lesson .popup__title {
  font-size: 20px;
  text-align: left;
}
.popup_review_lesson .stars_badge {
  background: none;
  padding: 0;
}
.popup_review_lesson .js-stars {
  margin: 0;
}
.popup_review_lesson .js-star {
  margin: 0 6px;
}
.popup_review_lesson .popup_review_lesson_block_stars {
  margin-top: 73px;
  padding-left: 10px;
}
.popup_review_lesson .popup_review_lesson_block_text {
  margin-top: 32px;
}
.popup_review_lesson .popup_review_lesson_block_text input {
  background: #FFFFFF;
  border: 1px solid #E6E6E8;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  outline: none;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #797780;
}
.popup_review_lesson .popup_review_lesson_block_text input::placeholder {
  color: #797780;
}
.popup_review_lesson .popup_review_lesson_block_text input:focus::placeholder {
  color: rgba(121, 119, 128, 0.6);
}
.popup_review_lesson .popup_review_lesson_block_button {
  margin-top: 32px;
}
.popup_review_lesson .popup_review_lesson_block_button .js-send_review_lesson {
  margin: 0;
}
.popup_review_lesson .popup_review_lesson_block_help {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: #191B1D;
  margin-top: 22px;
}

@media screen and (max-width: 450px) {
  .popup__wrapper {
    padding: 0;
    align-items: flex-end;
  }
  .popup__content {
    padding: 24px 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .popup__close {
    width: 32px;
    height: 32px;
    right: 16px;
  }
  .popup__close svg {
    margin: 6px;
  }
}
.loader {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}

.loader div {
  -webkit-animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
  -ms-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
}

.loader div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ADD471;
  margin: -4px 0 0 -4px;
}

.loader div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
  animation-delay: -0.036s;
}

.loader div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.loader div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
  animation-delay: -0.072s;
}

.loader div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.loader div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
  animation-delay: -0.108s;
}

.loader div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.loader div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
  animation-delay: -0.144s;
}

.loader div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.loader div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}

.loader div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.loader div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
  animation-delay: -0.216s;
}

.loader div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.loader div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
  animation-delay: -0.252s;
}

.loader div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.loader div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
  animation-delay: -0.288s;
}

.loader div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.courses-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-bottom: 30px;
}

.courses-grid__item {
  height: 100%;
}

.courses-card {
  height: 100%;
}

.courses-card__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  min-height: 462px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(40, 45, 60, 0.04), 0px 0px 2px rgba(40, 45, 60, 0.05), 0px 0px 1px rgba(40, 45, 60, 0.04);
  border-radius: 8px;
}

.courses-card__tag {
  position: absolute;
  display: flex;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.005em;
  top: 12px;
  left: 12px;
}
.courses-card__tag .courses-card__tag_item {
  background-color: #FFFFFF;
  padding: 6px 8px;
  color: #191B1D;
  border-radius: 4px;
  margin-right: 4px;
}
.courses-card__tag .courses-card__tag_item:first-child {
  background-color: #191B1D;
  color: #FFFFFF;
}

.courses-card__img {
  height: 160px;
  overflow: hidden;
  border-radius: 8px;
}
.courses-card__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.courses-card__img svg {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.courses-card__img-item {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.courses-card__content {
  margin-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.courses-card__button {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.courses-card__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-card__info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #DBDADE;
  padding-right: 12px;
}

.courses-card__info-item:last-child {
  border: 0;
}

.courses-card__info-item + .courses-card__info-item {
  margin: 0 0 0 12px;
}

.courses-card__info-icon {
  width: 24px;
  height: 24px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.courses-card__info-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #191B1D;
}

.courses-card__box {
  margin: 17px 0 0 0;
}

.courses-card__title {
  letter-spacing: -0.018em;
  color: #191B1D;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}

.courses-card__text {
  margin: 12px 0 0 0;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #797780;
  height: 75px;
  overflow: hidden;
}

.courses-card__author {
  margin-top: 12px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #191B1D;
}

.courses-card__btn {
  width: 270px;
  height: 50px;
}

.courses-card__price {
  display: flex;
  height: 32px;
  line-height: 32px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.courses-card__price .course_item_price {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.015em;
  color: #191B1D;
}
.courses-card__price .course_item_start {
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #797780;
}

.courses-card__price_item {
  width: 50%;
}

.courses-card__wrapper:hover .courses-card__title {
  color: #ADD471;
}

.courses-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.courses-empty__img {
  width: 100px;
  margin: 0 auto;
  text-align: center;
}

.courses-empty__img-item {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.courses-empty__content {
  max-width: 492px;
}

.courses-empty__text, .courses-search-failed__text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #797780;
}
.courses-empty__text a, .courses-search-failed__text a {
  color: #67A410;
}
.courses-empty__text a:hover, .courses-search-failed__text a:hover {
  color: #67A410;
}

.courses-empty__footer {
  margin-top: 30px;
}

.courses-empty__btn {
  max-width: 380px;
  height: 60px;
}

@media screen and (max-width: 1200px) {
  .page-content__title {
    font-size: 22px;
  }
}
@media screen and (max-width: 900px) {
  .courses-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .courses-card__button {
    display: block;
  }
  .courses-card__footer {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 600px) {
  .courses-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 25px;
  }
}
@media screen and (max-width: 450px) {
  .page-content__title {
    font-size: 20px;
  }
  .page-tabs__nav-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.course-single__grid {
  display: grid;
  grid-template-columns: auto 402px;
  overflow: hidden;
  background: #F5F5F7;
  border-radius: 12px;
}

.course-single__grid-content {
  margin-left: 48px;
  margin-top: 48px;
  margin-bottom: 48px;
}

.course-single__grid-img {
  text-align: right;
}

.course-single__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.018em;
  color: #202023;
}

.course-single__content-block {
  margin-top: 33px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #797780;
}
.course-single__content-block b {
  font-weight: 700;
  color: #202023;
}
.course-single__content-block .course-single__author {
  margin-top: 16px;
}
.course-single__content-block .course-single__price {
  margin-top: 12px;
}
.course-single__content-block .course-single__price b {
  font-size: 20px;
  letter-spacing: -0.015em;
}

.course-single__status-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: #898D93;
}

.course-single__status-progress {
  margin-top: 10px;
}

.course-single__status-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.course-single__content {
  margin-top: 30px;
}

.course-single__img {
  width: 100%;
}

.course-single__img-item {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 12px;
}

.course-single__description h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.018em;
  color: #202023;
  margin-bottom: 30px;
}
.course-single__description ul, .course-single__description ol {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #000000;
  list-style: auto inside;
}
.course-single__description ol > li {
  margin-bottom: 32px;
}
.course-single__description ol li > ul {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #797780;
  list-style: disc outside;
  margin-left: 34px;
  margin-top: 20px;
}
.course-single__description ol li > ul li {
  margin-bottom: 8px;
}
.course-single__description p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #1E1E22;
}

.course-single__description h4 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: -0.2px;
  color: #1E1E22;
  margin-bottom: 30px;
}

.course-single__description p + p {
  margin-top: 30px;
}

.course-single__description p + h4 {
  margin-top: 30px;
}

.course-single__description h4 + p {
  margin-top: 30px;
}

.course-single__description p + ul {
  margin-top: 30px;
}

.course-single__description ul + p {
  margin-top: 30px;
}

.course-single__description h4 + ul {
  margin-top: 30px;
}

.course-single__description ul + h4 {
  margin-top: 30px;
}

.course-singnle__info {
  margin-top: 13px;
}

.course-info {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #191B1D;
}
.course-info .course-info__item {
  padding-left: 12px;
  padding-right: 12px;
  border-right: 1px solid #DBDADE;
}
.course-info .course-info__item:first-child {
  padding-left: 0;
}
.course-info .course-info__item:last-child {
  border-right: none;
}

.course-info__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-info__title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: #898D93;
}

.course-info__text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.2px;
  color: #1E1E22;
}

.course-single__footer {
  margin-top: 40px;
}

.course-module__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;
}

.course-module__header .icon-mk {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.course-module__header.active .icon-mk {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.course-module__title {
  font-weight: 600;
  font-size: 25px;
  color: #1E1E22;
  letter-spacing: -0.2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-module__title span {
  margin-left: 5px;
}

.course-module__status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.course-module__status-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.course-module__status-progress {
  margin: 10px 0 0 0;
  width: 380px;
}

.course-module__status-text {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 14px;
  color: #898D93;
}

.lesson-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 275px;
  gap: 20px;
}

.lesson-grid__item {
  height: 100%;
}

.lesson-item {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #F5F5F7;
  border-radius: 8px;
}

.lesson-item_disabled {
  pointer-events: none;
}
.lesson-item_disabled .lesson-item__title {
  color: rgba(121, 119, 128, 0.32);
}
.lesson-item_disabled .lesson-item__desc {
  color: rgba(25, 27, 29, 0.32);
}

.lesson-item_complete .lesson-item {
  border: 1px solid #ADD471;
}

.lesson-item_disabled .lesson-item__progress {
  display: none;
}

.lesson-item_disabled .lesson-item__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.lesson-item__header-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.lesson-item__balls {
  font-size: 16px;
  color: #5C82D7;
}

.lesson-item__wrapper {
  height: 100%;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.lesson-item__title {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #797780;
}

.lesson-item__status {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.005em;
  border-radius: 4px;
  display: inline-block;
  padding: 6px 8px 6px 24px;
}

.lesson-item__status-completed {
  color: #67A410;
  background: #CFE5AE url("../images/lightning-green.svg") no-repeat 7px;
}

.lesson-item__status-failed {
  color: #5C82D7;
}

.lesson-item__status-start {
  color: #0C64F5;
  background: rgba(162, 194, 250, 0.2) url("../images/lightning-blue.svg") no-repeat 7px;
}

.lesson-item__status-close {
  color: #A4A2A9;
  background: #F5F5F7 url("../images/lock-grey.svg") no-repeat 7px;
}

.lesson-item__status-open {
  color: #F1B506;
  background: rgba(248, 204, 77, 0.32) url("../images/bell-yellow.svg") no-repeat 7px;
}

.lesson-item__content {
  margin-top: 15px;
}

.lesson-item__desc {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #191B1D;
}

.lesson-item__footer {
  padding-top: 35px;
  margin-top: auto;
  width: 100%;
}

.lesson-item__progress {
  width: 100%;
}

.lesson-item__date {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.lesson-item__date::after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background: url("../images/lock.svg") center center/contain no-repeat;
}

.lesson-item__date-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #898D93;
}

.course-single__modules-item {
  margin-bottom: 32px;
}

.course-single__status {
  margin-top: 40px;
  width: 90%;
}

.course-single__status-info {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #A4A2A9;
  text-align: center;
}
.course-single__status-info b {
  font-weight: 700;
  color: #191B1D;
  margin-left: 8px;
}
.course-single__status-info .course-single__status-info-item {
  display: flex;
  align-items: center;
}
.course-single__status-info .course-single__status-info-item::before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 4px;
}
.course-single__status-info .course-single__status-info-item:first-child {
  text-align: left;
}
.course-single__status-info .course-single__status-info-item:last-child {
  text-align: right;
}
.course-single__status-info .course-single__status-info-item-study::before {
  background: url("../images/course-study.svg") no-repeat;
}
.course-single__status-info .course-single__status-info-item-tasks::before {
  background: url("../images/course-tasks.svg") no-repeat;
}
.course-single__status-info .course-single__status-info-item-clock::before {
  background: url("../images/course-clock.svg") no-repeat;
}

.course-single__description-grid {
  font-family: "Nunito Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  border: 1px solid #F5F5F7;
  border-radius: 12px;
  display: flex;
  padding: 32px;
  margin-bottom: 60px;
}
.course-single__description-grid .course-single__description-grid-item {
  width: 100%;
  flex: 1 0 0%;
}
.course-single__description-grid .course-single__description-grid-item-title {
  font-weight: 700;
  color: #000000;
  margin-bottom: 8px;
}
.course-single__description-grid .course-single__description-grid-item-text {
  font-weight: 400;
  color: #797780;
}

.course-single-pay-wait-wrapper {
  display: flex;
  justify-content: flex-start;
}
.course-single-pay-wait-wrapper .course-single-pay-wait-item {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #797780;
}
.course-single-pay-wait-wrapper .course-single-pay-wait-item a {
  color: #67A410;
}
.course-single-pay-wait-wrapper .course-single-pay-wait-item:first-child {
  margin-right: 6px;
}

@media screen and (max-width: 1100px) {
  .course-single__grid {
    grid-template-columns: auto 300px;
  }
  .course-single__img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    height: 370px;
  }
  .course-module__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .course-module__status {
    margin-top: 30px;
  }
  .course-review__input {
    margin-left: 15px;
  }
  .lesson-grid {
    grid-template-columns: repeat(3, minmax(0, 270px));
  }
}
@media screen and (max-width: 991px) {
  .course-single__grid {
    grid-template-columns: auto;
  }
  .course-single__grid-img {
    order: 1;
    text-align: center;
    height: 370px;
  }
  .course-single__grid-img .course-single__img-item {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .course-single__grid-content {
    margin: 16px;
    order: 2;
  }
  .course-single__status {
    width: 100%;
  }
  .course-single__title {
    font-size: 20px;
  }
  .course-review__wrapper {
    height: auto;
    padding: 15px 0;
  }
  .course-review__content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .course-review__mark, .course-review__input, .course-review__submit {
    margin: 5px 10px;
  }
  .course-review__input-item {
    width: 100%;
  }
  .course-single__author, .course-single__price {
    font-size: 14px;
  }
  .lesson-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .course-module__title {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .course-module__title span {
    width: 100%;
    padding-left: 56px;
  }
}
@media screen and (max-width: 640px) {
  .course-single__description-grid {
    flex-direction: column;
    padding: 0;
    margin: 0;
    border: none;
  }
  .course-single__description-grid .course-single__description-grid-item {
    margin-bottom: 16px;
  }
  .page-content__header + .page-content__wrapper {
    padding-top: 20px;
  }
  .course-single__status-info .course-single__status-info-item {
    flex-direction: column;
    align-items: normal;
  }
  .course-single__status-info .course-single__status-info-item span {
    display: none;
  }
  .course-single__status-info .course-single__status-info-item b {
    margin: 0;
  }
  .lesson-grid {
    grid-template-columns: 1fr;
  }
}
#html_review_lesson .page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.js-block_review_lesson {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  font-size: 16px;
  color: #95929C;
  height: 70px;
}

.js-stars {
  margin-left: 15px;
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 0;
}

.js-star {
  display: flex;
  margin: 0 5px;
}

.js-star:hover svg path,
.js-star:hover ~ .js-star svg path,
.js-star.my_rating svg path,
.js-star.my_rating ~ .js-star svg path {
  fill: #ADD471;
  stroke: #ADD471;
}

.js-star svg path {
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

#html_review_lesson {
  overflow-y: auto;
}

.js-block_review_lesson input {
  margin-left: 45px;
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 370px;
  height: 40px;
  font-size: 16px;
  padding: 8px 16px;
  outline: none;
}

.js-block_review_lesson input::placeholder {
  color: #95929C;
}

.js-send_review_lesson {
  margin-left: 20px;
  line-height: 8px;
}

.stars_block {
  display: flex;
}

.stars_badge {
  background: #F5F5F7;
  border-radius: 8px;
  padding: 8px 32px;
  display: flex;
}

.stars_caption {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #191B1D;
}

.js-button_review_lesson {
  display: none;
}

@media screen and (max-width: 1040px) {
  .js-block_review_lesson input {
    margin-left: 25px;
  }
  .stars_badge {
    padding: 8px 12px;
  }
}
@media screen and (max-width: 991px) {
  #html_review_lesson {
    padding: 10px 0;
  }
  .js-block_review_lesson input {
    font-size: 14px;
    height: 35px;
    width: 100%;
    margin-left: 0px;
  }
  .js-block_review_lesson {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .js-block_review_lesson > div {
    margin: 0 5px;
  }
  .js-star {
    margin: 0 2px;
  }
}
@media screen and (max-width: 880px) {
  .js-block_review_lesson {
    display: none;
  }
  .js-button_review_lesson {
    display: block;
  }
}
.feedback-block__title {
  font-size: 17px;
  font-weight: 600;
  color: #1E1E22;
}

.feedback-block__title span {
  color: #1E1E22;
}

.feedback-block__note {
  font-size: 16px;
  color: #898D93;
  margin-top: 15px;
}

.feedback-block__content {
  margin-top: 15px;
}

.feedback-block__list {
  list-style: none;
}

.feedback-block__list-item + .feedback-block__list-item {
  margin-top: 15px;
}

.feedback-block__textarea {
  width: 100%;
  height: 250px;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  resize: none;
  font-size: 16px;
  outline: none;
}

.feedback-block__textarea::-webkit-input-placeholder {
  color: #95929C;
}

.feedback-block__textarea::-moz-placeholder {
  color: #95929C;
}

.feedback-block__textarea:-ms-input-placeholder {
  color: #95929C;
}

.feedback-block__textarea::-ms-input-placeholder {
  color: #95929C;
}

.feedback-block__textarea::placeholder {
  color: #95929C;
}

.feedback-block__rate {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 24px));
  gap: 30px;
}

.feedback-wrapper__grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 130px;
}

.feedback-wrapper__block + .feedback-wrapper__block {
  margin-top: 30px;
}

.feedback-wrapper__btn {
  width: 100%;
  max-width: 390px;
  height: 60px;
  font-size: 18px;
  font-weight: 600;
}

.payment-succes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.payment-succes__wrapper {
  max-width: 480px;
  width: 100%;
}

.payment-succes__title {
  text-align: center;
}

.payment-succes__img {
  width: 115px;
  margin: 35px auto 20px auto;
  text-align: center;
}

.payment-succes__img-item {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.payment-succes__text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #797780;
  margin-top: 16px;
}
.payment-succes__text a {
  color: #67A410;
}

.payment-succes__btn {
  margin-top: 35px;
}

.certificate-card__wrapper {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #E6E6E8;
  margin-bottom: 32px;
  height: 80px;
}

.certificate-card__text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #797780;
  display: flex;
  margin-top: 13px;
}
.certificate-card__text .certificate-card__text-item {
  padding: 0 12px;
  border-right: 1px solid #DBDADE;
}
.certificate-card__text .certificate-card__text-item:first-child {
  padding-left: 0;
}
.certificate-card__text .certificate-card__text-item:last-child {
  border-right: none;
}

.certificate-card__name {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #191B1D;
}

.certificate-card__content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80%;
}

.certificate-card__footer {
  width: 20%;
  text-align: right;
  padding-top: 6px;
}

.certificate-card__btn {
  max-width: 200px;
  height: 50px;
}

.certificate-info__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #5C82D7;
}

.certificate-info__date {
  margin: 15px 0 0 0;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 136%;
  color: #898D93;
}

.certificate-info__footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 270px));
  gap: 20px;
  margin: 40px 0 0 0;
}

.certificate-info__btn {
  height: 50px;
}

.certificate-single__wrapper {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 12px rgba(92, 130, 215, 0.08);
  box-shadow: 0px 0px 12px rgba(92, 130, 215, 0.08);
  border-radius: 15px;
  max-width: 650px;
  width: 100%;
  padding: 12px 0 25px 36px;
  position: relative;
  overflow: hidden;
}

.certificate-single__wrapper::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 120px;
  height: 120px;
  display: block;
}

.certificate-single__text {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1E1E22;
}

.certificate-single__name {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1E1E22;
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}

.certificate-single__name::before {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -4px;
  width: calc(100% - 40px);
  height: 4px;
  background: #F8D47A;
}

.certificate-single__content-header {
  margin: 18px 0 0 0;
}

.certificate-single__content-name {
  margin: 27px 0 0 0;
}

.certificate-single__content-footer {
  margin: 40px 0 0 0;
}

.certificate-single__footer {
  margin: 50px 0 0 0;
  padding: 0 30px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.certificate-single__share {
  max-width: 650px;
  position: relative;
}

.certificate-logo__text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  color: #1E1E22;
}

.certificate-share {
  position: absolute;
  left: calc(100% + 5px);
  top: -36px;
}

.certificate-share__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 136%;
  color: #898D93;
}

.certificate-share__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 0 15px;
}

.certificate-share__link {
  width: 36px;
  height: 36px;
  display: block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.certificate-share__link + .certificate-share__link {
  margin: 0 0 0 15px;
}

.certificate__single {
  margin: 40px 0 0 0;
}

.profile-content__block {
  margin-top: 60px;
}

@media screen and (max-width: 991px) {
  .certificate-info__footer {
    margin: 30px 0 0 0;
  }
  .certificate__single {
    margin: 30px 0 0 0;
  }
  .certificate-single__share {
    margin: 30px 0 0 0;
  }
  .certificate-share {
    position: relative;
    left: 0;
    top: 0;
  }
}
@media screen and (max-width: 600px) {
  .certificate-card__content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .certificate-single__wrapper {
    padding: 7px 0 15px 20px;
  }
  .certificate-single__text {
    font-size: 8px;
    line-height: 10px;
  }
  .certificate-single__name {
    font-size: 11px;
    line-height: 14px;
  }
  .certificate-single__name::before {
    left: 12px;
    width: calc(100% - 24px);
  }
  .certificate-single__footer {
    margin: 25px 0 0 0;
    padding: 0 18px 0 0;
  }
  .certificate-single__content-header {
    margin: 10px 0 0 0;
  }
  .certificate-single__content-name {
    margin: 14px 0 0 0;
  }
  .certificate-single__content-footer {
    margin: 22px 0 0 0;
  }
  .certificate-logo__text {
    font-size: 5px;
    line-height: 6px;
  }
}
.profile-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile-info__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile-info__logout a {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #797780;
  display: flex;
  padding-top: 13px;
}
.profile-info__logout svg {
  margin-right: 8px;
}

.profile-info__logout:hover a {
  color: #67A410;
}
.profile-info__logout:hover svg path {
  stroke: #67A410;
}

.profile-info__img {
  width: 56px;
  height: 56px;
  border-radius: 12px;
  background: #F5F5F7;
}
.profile-info__img .icon-mk {
  margin: 16px;
}
.profile-info__img-item {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-info__content {
  margin-left: 20px;
}

.profile-info__text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #47494A;
}

.profile-info__text-small {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #797780;
}

.profile-info__balls {
  margin-left: 40px;
}

.profile-info__desc {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #898D93;
  max-width: 600px;
}

.profile-content__block + .profile-content__block {
  margin-top: 30px;
}

.profile-content__box {
  margin-top: 15px;
}

.profile-content__text {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #898D93;
}

.profile-content__text-link {
  color: #67A410;
  -webkit-transition: 0.5s color;
  -o-transition: 0.5s color;
  transition: 0.5s color;
}

.profile-content__text-small {
  max-width: 435px;
}

.profile-content__desc {
  font-size: 16px;
  color: #95929C;
}

.profile-content__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.profile-content__input {
  font-family: "Nunito Sans";
  max-width: 470px;
  display: flex;
  padding: 5px;
  height: 60px;
  border-radius: 5px;
  background-color: rgba(245, 245, 247, 0.6);
  transition: all 0.3s ease;
  cursor: pointer;
}
.profile-content__input:hover {
  background-color: #F5F5F7;
}

.profile-content__input-block {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  width: 100%;
}

.profile-content__input-close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  transition: width 0.3s ease;
  cursor: pointer;
}
.profile-content__input-close svg {
  stroke: #ED584E;
  visibility: hidden;
}
.profile-content__input-close:hover svg {
  transform: scale(1.2);
}

.profile-content__input-error {
  background-color: #FDEEED;
}

.profile-content__input-text {
  color: #A4A2A9;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  height: 0;
  visibility: hidden;
  transition: height 0.3s ease;
  background-color: transparent;
}

.profile-content__input-item {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  height: 100%;
  transition: height 0.3s ease;
  background-color: transparent;
}

.profile-content__input-item::placeholder {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #A4A2A9;
  margin-left: 3px;
}

.profile-content__input:focus-within {
  outline: 1px solid #ADD471;
  background-color: #FFFFFF;
}

.profile-content__input-error:focus-within {
  background-color: unset;
  outline-color: #ED584E;
}
.profile-content__input-error:focus-within .profile-content__input-close {
  width: 15%;
}
.profile-content__input-error:focus-within .profile-content__input-close svg {
  visibility: visible;
}
.profile-content__input-error:focus-within .profile-content__input-item::placeholder {
  color: #ED584E;
}
.profile-content__input-error:focus-within .profile-content__input-text {
  color: #ED584E;
}

.profile-content__input:focus-within .profile-content__input-text {
  height: 50%;
  visibility: visible;
}

.profile-content__input:focus-within .profile-content__input-item {
  height: 50%;
}

.profile-save {
  pointer-events: none;
  cursor: unset;
  background-color: #F0F0F1;
  color: #C8C7CB;
}

.profile-content__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 370px));
  gap: 30px;
}

.profile-content__gender {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile-content__gender-block + .profile-content__gender-block {
  margin: 0 0 0 15px;
}

.profile-content__btn {
  max-width: 270px;
  height: 50px;
}

@media screen and (max-width: 700px) {
  .profile-info__balls {
    margin: 15px 0 0 0;
  }
  .profile-info__desc {
    margin-top: 15px;
  }
  .certificate-card__wrapper {
    flex-direction: column;
    height: auto;
    padding-bottom: 24px;
  }
  .certificate-card__name {
    font-size: 16px;
  }
  .certificate-card__content {
    width: 100%;
  }
  .certificate-card__footer {
    width: 100%;
    text-align: left;
    padding-top: 22px;
  }
  .certificate-card__footer .btn {
    font-size: 12px;
    padding: 12px 24px;
  }
}
@media screen and (max-width: 500px) {
  .profile-info__text-small {
    display: none;
  }
  .profile-info__logout span {
    display: none;
  }
  .profile-info__text {
    font-size: 14px;
    max-width: 200px;
    overflow-x: hidden;
  }
  .tabs-nav-desktop {
    display: none;
  }
  .tabs-nav-mobile {
    display: block;
  }
  .profile-info__logout svg path {
    stroke: #67A410;
  }
}
@media screen and (max-width: 420px) {
  .profile-info__text {
    max-width: 155px;
  }
}
.box-404 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box-404__wrapper {
  max-width: 465px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box-404__title, .box-404__desc {
  line-height: 1;
  text-align: center;
}

.box-404__desc {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #A4A2A9;
  margin-top: 30px;
}

.box-404__title {
  font-size: 90px;
  font-weight: 600;
  color: #ADD471;
}

.box-404__btn {
  margin-top: 40px;
}

@media screen and (max-width: 600px) {
  .box-404__desc {
    font-size: 18px;
  }
}