.icon-mk {
    display: inline-block;
    min-width: 28px;
    min-height: 28px;
    background-image: url("../images/icons.svg");
}

.icon-mk-medium {
    width: 20px;
    height: 20px;
}

.icon-mk-bell-big {
    background-position: -17px -15px;
}

.icon-mk-bell-medium {
    background-position: -58px -20px;
}

.icon-mk-profile-big {
    background-position: -21px -50px;
}

.icon-mk-profile-medium {
    background-position: -58px -50px;
}

.icon-mk-logout-big {
    background-position: -19px -360px;
}

.icon-mk-logout-medium {
    background-position: -58px -360px;
}

.icon-mk-arrow-down-big {
    background-position: -19px -72px;
}

.icon-mk-arrow-up-big {
    background-position: -10px -10px;
}

.icon-mk-student-big {
    background-position: -10px -10px;
}

.icon-mk-tasks-big {
    background-position: -10px -10px;
}

.icon-mk-clock-big {
    background-position: -10px -10px;
}

.icon-mk-menu-medium {
    background-position: -60px -132px;
}

.active {
    .icon-mk-menu-medium {
        background-position: -58px -164px;
    }
}
