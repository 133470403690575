.courses-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-bottom: 30px;
}

.courses-grid__item {
  height: 100%;
}

.courses-card {
  height: 100%;
}

.courses-card__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    min-height: 462px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(40, 45, 60, 0.04), 0px 0px 2px rgba(40, 45, 60, 0.05), 0px 0px 1px rgba(40, 45, 60, 0.04);
    border-radius: 8px;
}

.courses-card__tag {
    position: absolute;
    display: flex;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    top: 12px;
    left: 12px;

    .courses-card__tag_item {
        background-color: #FFFFFF;
        padding: 6px 8px;
        color: #191B1D;
        border-radius: 4px;
        margin-right: 4px;
    }
    .courses-card__tag_item:first-child {
        background-color: #191B1D;
        color: #FFFFFF;
    }
}

.courses-card__img {
    height: 160px;
    overflow: hidden;
    border-radius: 8px;
    img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    svg {
        width: 100%;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.courses-card__img-item {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.courses-card__content,  {
    margin-top: 20px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}

.courses-card__button {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.courses-card__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.courses-card__info-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #DBDADE;
    padding-right: 12px;
}
.courses-card__info-item:last-child {
    border: 0;
}

.courses-card__info-item + .courses-card__info-item {
    margin: 0 0 0 12px;
}

.courses-card__info-icon {
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.courses-card__info-text {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #191B1D;
}

.courses-card__box {
    margin: 17px 0 0 0;
}

.courses-card__title {
    letter-spacing: -0.018em;
    color: #191B1D;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
}

.courses-card__text {
    margin: 12px 0 0 0;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #797780;
    height: 75px;
    overflow: hidden;
}

.courses-card__author {
    margin-top: 12px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #191B1D;
}

.courses-card__footer {

}

.courses-card__btn {
    width: 270px;
    height: 50px;
}

.courses-card__price {
    display: flex;
    height: 32px;
    line-height: 32px;
    font-family: 'Nunito Sans';
    font-style: normal;

    .course_item_price {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.015em;
        color: #191B1D;

    }
    .course_item_start {
        text-align: right;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #797780;
    }
}

.courses-card__price_item {
    width: 50%;
}

.courses-card__wrapper:hover {
    .courses-card__title {
        color: #ADD471;
    }
}

.courses-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.courses-empty__img {
    width: 100px;
    margin: 0 auto;
    text-align: center;
}

.courses-empty__img-item {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.courses-empty__content {
    max-width: 492px;
}

.courses-empty__text, .courses-search-failed__text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #797780;

    a {
        color: #67A410;
    }

    a:hover {
        color: #67A410;
    }
}

.courses-empty__footer {
    margin-top: 30px;
}

.courses-empty__btn {
    max-width: 380px;
    height: 60px;
}

@media screen and (max-width: 1200px) {

    .page-content__title {
        font-size: 22px;
    }
}

@media screen and (max-width: 900px) {

    .courses-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .courses-card__button {
        display: block;
    }
    .courses-card__footer {
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 600px) {
    .courses-grid {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 25px;
    }
}

@media screen and (max-width: 450px) {
    .page-content__title {
        font-size: 20px;
    }

    .page-tabs__nav-item {
        padding-left: 10px;
        padding-right: 10px;
    }
}
