.box-404 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.box-404__wrapper {
    max-width: 465px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.box-404__title, .box-404__desc {
    line-height: 1;
    text-align: center;
}

.box-404__desc {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #A4A2A9;
    margin-top: 30px;
}

.box-404__title {
    font-size: 90px;
    font-weight: 600;
    color: #ADD471;
}

.box-404__btn {
    margin-top: 40px;
}

@media screen and (max-width: 600px) {
    .box-404__desc {
        font-size: 18px;
    }
}
