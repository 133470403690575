.container {
    max-width: 1220px;
    padding: 0 25px;
    width: 100%;
    margin: 0 auto;
}

.page-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
}

.page-wrapper__header, .page-wrapper__footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #fff;
}

.page-wrapper__content {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding: 40px 0;
}

.page-wrapper__content_big {
    padding: 40px 0;
}

.page-wrapper__content_banner {
    padding: 160px 0 115px 0;
}

.page-content__header {
    position: relative;
}

.page-content__header-block + .page-content__header-block {
    margin-top: 40px;
}

.page-content__header-back {
    position: absolute;
    left: 0;
    top: -40px;
}

.page-content__header-back + .page-content__header-block {
    padding-top: 40px;
}

.page-content__title {
    color: #191B1D;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.page-content__subtitle {
    margin: 15px 0 0 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: #5C82D7;
}

.page-content__desc {
    margin-top: 40px;
}

.page-content__header + .page-content__wrapper {
    padding-top: 30px;
}

.tabs-nav {
    z-index: 1;
    position: relative;
    border-bottom: 2px solid #F5F5F7;
}

.page-tabs__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.page-tabs__nav-item {
    height: 36px;
    color: #797780AD;
    border-bottom: 2px solid transparent;
    -webkit-transition: .5s border-bottom-color;
    -o-transition: .5s border-bottom-color;
    transition: .5s border-bottom-color;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 16px;
    position: relative;
    bottom: -2px;
}

.page-tabs__nav-item.active {
    color: #191B1D;
    border-bottom-color: #191B1D;
}

.page-tabs__nav-link {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: inherit;
    -webkit-transition: .5s color;
    -o-transition: .5s color;
    transition: .5s color;
    outline: none;
}

.page-tabs__content {
    margin-top: 32px;
}

.page-tabs__content-small {
    padding-top: 40px;
}

.page-nav {
    width: 100%;
}

.page-nav__item {
    display: none;
}

.page-nav__item_desktop {
    display: block;
}

.page-nav__item-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 55px;
    border-radius: 10px;
    padding: 0 18px;
    -webkit-transition: .5s background-color;
    -o-transition: .5s background-color;
    transition: .5s background-color;
}

.page-nav__item-link:hover {
    background-color: rgba(235, 235, 235, 0.5);
}

.page-nav__item-link_not-read {
    position: relative;
}

.page-nav__item-link_not-read::after {
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    border-radius: 50%;
    margin-left: auto;
    background-color: #5C82D7;
}

.page-nav__item-icon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.page-nav__item-icon svg {
    width: 100%;
    height: 100%;
}

.page-nav__item-text {
    margin-left: 15px;
    font-weight: 600;
    font-size: 17px;
    color: #232B3F;
}

.tabs-nav-mobile {
    display: none;
}

.breadcrumb {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    display: flex;

    .breadcrumb-item {
        color: #67A410;
        padding-right: 8px;
        padding-left: 8px;
        border-right: 1px solid rgba(121, 119, 128, 0.2);

        a {
            color: #A4A2A9;
        }
    }

    .breadcrumb-item:first-child {
        padding-left: 0;
    }
    .breadcrumb-item:last-child {
        border-right: none;
    }

    .breadcrumb-item-icon {
        a {
            display: flex;
            justify-content: flex-start;
            line-height: 22px;

            svg {
                margin-right: 6px;
            }
        }
    }
}

.breadcrumb-mobile {
    display: none;
}

.progress-bar {
    width: 100%;
    height: 6px;
    background: #E6E6E8;
    border-radius: 12px;
    position: relative;
}

.progress-bar_big {
    height: 10px;
    border-radius: 0;
}

.progress-bar-current {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    background: #ADD471;
    border-radius: 12px;
}

.click-url {
    cursor: pointer;
}

#body_overlay {
    background: rgba(0, 0, 0, 0.2);
}

#body_overlay.active {
    display: none;
}

@media screen and (max-width: 480px) {
    .page-tabs__nav-link {
        span {
            display: none;
        }
    }
}

@media screen and (max-width: 450px) {
    .container {
        padding: 0 16px;
    }

    .breadcrumb-mobile {
        display: flex;
    }
    .breadcrumb-desktop {
        display: none;
    }

    .page-tabs__nav-item {
        padding-left: 12px;
        padding-right: 12px;
        margin-right: 0;
    }

    #body_overlay.active {
        display: block;
    }
}
