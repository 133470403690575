.profile-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.profile-info__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.profile-info__logout {
    a {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: #797780;
        display: flex;
        padding-top: 13px;
    }

    svg {
        margin-right: 8px;
    }
}

.profile-info__logout:hover {
    a {
        color: #67A410;
    }
    svg {
        path {
            stroke: #67A410;
        }
    }
}

.profile-info__img {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    background: #F5F5F7;

    .icon-mk {
        margin: 16px;
    }
    img {

    }
}

.profile-info__img-item {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.profile-info__content {
    margin-left: 20px;
}

.profile-info__text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #47494A;
}

.profile-info__text-small {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #797780;
}

.profile-info__balls {
    margin-left: 40px;
}

.profile-info__desc {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #898D93;
    max-width: 600px;
}

.profile-content__block + .profile-content__block {
    margin-top: 30px;
}

.profile-content__box {
    margin-top: 15px;
}

.profile-content__text {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #898D93;
}

.profile-content__text-link {
    color: #67A410;
    -webkit-transition: .5s color;
    -o-transition: .5s color;
    transition: .5s color;
}

.profile-content__text-small {
    max-width: 435px;
}

.profile-content__desc {
    font-size: 16px;
    color: #95929C;
}

.profile-content__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.profile-content__input {
    font-family: 'Nunito Sans';
    max-width: 470px;
    display: flex;
    padding: 5px;
    height: 60px;
    border-radius: 5px;
    background-color: rgba(245, 245, 247, 0.6);;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #F5F5F7;
    }
}

.profile-content__input-block {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
    width: 100%;
}

.profile-content__input-close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    transition: width 0.3s ease;
    cursor: pointer;
    svg {
        stroke: #ED584E;
        visibility: hidden;
    }
    &:hover {
        svg {
            transform: scale(1.2);
        }
    }
}

.profile-content__input-error {
    background-color: #FDEEED;
}

.profile-content__input-text {
    color: #A4A2A9;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    height: 0;
    visibility: hidden;
    transition: height 0.3s ease;
    background-color: transparent;
}

.profile-content__input-item {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    height: 100%;
    transition: height 0.3s ease;
    background-color: transparent;
}

.profile-content__input-item::placeholder {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #A4A2A9;
    margin-left: 3px;
}

.profile-content__input:focus-within {
    outline: 1px solid #ADD471;
    background-color: #FFFFFF;
}

.profile-content__input-error:focus-within {
    background-color: unset;
    outline-color: #ED584E;
    .profile-content__input-close {
        width: 15%;
        svg {
            visibility: visible;
        }
    }
    .profile-content__input-item::placeholder {
        color: #ED584E;
    }
    .profile-content__input-text {
        color: #ED584E;
    }
}

.profile-content__input:focus-within .profile-content__input-text {
    height: 50%;
    visibility: visible;
}

.profile-content__input:focus-within .profile-content__input-item {
    height: 50%;
}

.profile-save {
    pointer-events: none;
    cursor: unset;
    background-color: #F0F0F1;
    color: #C8C7CB;
}

.profile-content__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 370px));
    gap: 30px;
}

.profile-content__gender {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.profile-content__gender-block + .profile-content__gender-block {
    margin: 0 0 0 15px;
}

.profile-content__btn {
    max-width: 270px;
    height: 50px;
}

@media screen and (max-width: 700px) {
    .profile-info__balls {
        margin: 15px 0 0 0;
    }
    .profile-info__content {

    }
    .profile-info__desc {
        margin-top: 15px;
    }

    .certificate-card__wrapper {
        flex-direction: column;
        height: auto;
        padding-bottom: 24px;
    }
    .certificate-card__name {
        font-size: 16px;
    }
    .certificate-card__content {
        width: 100%;
    }
    .certificate-card__footer {
        width: 100%;
        text-align: left;
        padding-top: 22px;

        .btn {
            font-size: 12px;
            padding: 12px 24px;
        }
    }
}

@media screen and (max-width: 500px) {
    .profile-info__text-small {
        display: none;
    }

    .profile-info__logout {
        span {
            display: none;
        }
    }

    .profile-info__text {
        font-size: 14px;
        max-width: 200px;
        overflow-x: hidden;
    }

    .tabs-nav-desktop {
        display: none;
    }
    .tabs-nav-mobile {
        display: block;
    }

    .profile-info__logout {
        svg {
            path {
                stroke: #67A410;
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .profile-info__text {
        max-width: 155px;
    }
}
