.feedback-block__title {
    font-size: 17px;
    font-weight: 600;
    color: #1E1E22;
}

.feedback-block__title span {
    color: #1E1E22;
}

.feedback-block__note {
    font-size: 16px;
    color: #898D93;
    margin-top: 15px;
}

.feedback-block__content {
    margin-top: 15px;
}

.feedback-block__list {
    list-style: none;
}

.feedback-block__list-item + .feedback-block__list-item {
    margin-top: 15px;
}

.feedback-block__textarea {
    width: 100%;
    height: 250px;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    resize: none;
    font-size: 16px;
    outline: none;
}

.feedback-block__textarea::-webkit-input-placeholder {
    color: #95929C;
}

.feedback-block__textarea::-moz-placeholder {
    color: #95929C;
}

.feedback-block__textarea:-ms-input-placeholder {
    color: #95929C;
}

.feedback-block__textarea::-ms-input-placeholder {
    color: #95929C;
}

.feedback-block__textarea::placeholder {
    color: #95929C;
}

.feedback-block__rate {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 24px));
    gap: 30px;
}

.feedback-wrapper__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 130px;
}

.feedback-wrapper__block + .feedback-wrapper__block {
    margin-top: 30px;
}

.feedback-wrapper__btn {
    width: 100%;
    max-width: 390px;
    height: 60px;
    font-size: 18px;
    font-weight: 600;
}
